
import { useNavigate } from "react-router-dom";



export default function PageNotFound(){


    let navigate = useNavigate();
    function handleClick() {
      navigate("/");
    }



    return(
        <div style={{ display: 'flex', alignItems: 'center','flexDirection' :'column', justifyContent: 'center', height: '100vh' }}>
            <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>Oops! The page you are looking for does not exist.</h1>
            <br/>
            <div onClick={handleClick}  style={{
                'backgroundColor':'#1e1925',
                'border':'none',
                'color' : 'white',
                'cursor' : 'pointer',
                'display' :'flex',
                'alignItems':'center',
                'padding' : '15px 40px',
                'borderRadius' : '40px'
                }} > 
                <p>Back to home</p>
                &nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                   <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg>
                
               </div>
          </div>

    )
}