import { useState } from "react";
import "../css/slider.css"
import sample from '../assets/1.jpg'
import h from '../assets/breakfast.jpeg'
import a from '../assets/review/1.png'
import b from '../assets/review/6.jpeg'
import c from '../assets/review/3.png'
import d from '../assets/review/4.png'
import e from '../assets/review/5.png'

const ReviewSlider = () => {

    const cards = [
        { image: a,comment : "Beautiful garden, great service, nice rooms with hot water & so peaceful place to relax & chill out!!!❤️😇⭐️⭐️⭐️⭐️👍",name : "Smita Shakya" },
        { image: b,comment : "One of the nice places you want to be.",name : "Birgit Euringer" },
        { image: c,comment : "Had a great time Beautiful garden and very very quiet.",name : "Bishal lama Lopchan" },
        { image: d,comment : "This one here is great to hang out . Hotel rooms are clean and at utmust quality service.",name : "Prabin Bagale" },
        { image: e,comment : "Had a group of my Bangali freinds.The experience was very nice.",name : "Suvajit Banerjee" },
        { image: a,comment : "Beautiful garden, great service, nice rooms with hot water & so peaceful place to relax & chill out!!!❤️😇⭐️⭐️⭐️⭐️👍",name : "Smita Shakya" },
        { image: b,comment : "Stayed here for 3 nights, beautiful environment, helping staffs, great food.",name : "Niraj Ayer" },
        { image: c,comment : "Had a great time Beautiful garden and very very quiet.",name : "Bishal lama Lopchan" },
        { image: d,comment : "This one here is great to hang out . Hotel rooms are clean and at utmust quality service.",name : "Prabin Bagale" },
        { image: e,comment : "Had a group of my Bangali freinds.The experience was very nice.",name : "Suvajit Banerjee" },

      ];
      
      
      const [startIndex, setStartIndex] = useState(0);

      const handlePrev = () => {
        setStartIndex((prevIndex) => (prevIndex === 0 ? cards.length - 3 : prevIndex - 1));
      };
    
      const handleNext = () => {
        setStartIndex((prevIndex) => (prevIndex === cards.length - 3 ? 0 : prevIndex + 1));
      };
    
      return (
        <div className="reviewSlider" >
         
         <div className="innerSlider" >

        <div className="desc-review" >
            <h1>Customer say</h1><br/>
            <p>Best service you could imagine for a wildlife tours!</p>
            <br/>
            <button>
                <a target= '_blank' href = "https://www.google.com/travel/search?q=travellers%20jungle%20camp&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4284970%2C4291517%2C4308226%2C4597339%2C4814050%2C4874190%2C4893075%2C4965990%2C10208620%2C72277293%2C72280812%2C72302247%2C72317059%2C72406588%2C72412680%2C72414906%2C72421566%2C72430562%2C72440517%2C72442338%2C72448521%2C72458707%2C72470899%2C72471395%2C72473738%2C72476332&hl=en-NP&gl=np&cs=1&ssta=1&ts=CAEaRwopEicyJTB4Mzk5NGVlNDA2NzNlNTAwOToweDhiNGVlMDg1NDE4ZmRmZWISGhIUCgcI6A8QARgQEgcI6A8QARgRGAEyAhAA&qs=CAEyFENnc0k2Ny1fak5TUXVLZUxBUkFCOAJCCQnr349BheBOi0IJCevfj0GF4E6L&ap=ugEHcmV2aWV3cw&ictx=1&ved=0CAAQ5JsGahcKEwiYrNPSrOGDAxUAAAAAHQAAAAAQBQ">See More</a>
            </button>

        </div>
        <div className="card-slider">
          <div className="card-container">
            {cards.slice(startIndex, startIndex + 3).map((card, index) => (
              <div
                key={index + startIndex}
                className={`card ${index === 1 ? 'center' : ''}`}
              >
                 <p>{card.comment}</p>
                 <br/>
                <div className="imageDiv" >
                <img src={card.image} alt={`Card ${index + 1}`} />
                </div>
                <br/>
                <p><b>{card.name}</b></p>
               

              </div>
            ))}
          </div>
          <div className="arrow-container">
            <button className="btnLeft" onClick={handlePrev}>&lt;</button>
            <button className="btnRight" onClick={handleNext}>&gt;</button>
          </div>
        </div>
        </div>
        </div>
      );
    
  };
  
  export default ReviewSlider;