
import a from '../assets/safari.JPG';
import b from '../assets/manSelfieWithJeep.jpeg'
import c from '../assets/groupPhoto.jpeg'
import d from '../assets/2.jpg';
import e from '../assets/jeep_safari.jpeg';
import f from '../assets/bg.jpg'
import { Link } from 'react-router-dom';


const GallerySection = ({t})=>{


    return(
        <div className="gallerySection" style = {{
            letterSpacing : "2px"
        }}  >
            <div className="headerSection" >
            <h1>{t('shortglances.title')} <span style = {{color : "green"}} >{t('shortglances.title2')}</span></h1>
            <hr style={{height : "0px", width : "10rem" ,margin:"auto"}} />
            <br/>
            <p>
            {t('shortglances.body')}
            </p>

            </div>

            <div className='imageSection' >
                <img src = {a} />
                <img src = {b} />
                <img src = {c} />
                <img src = {d} />
                <img src = {e} />
                <img src = {f} />
            </div>
            <br/>
            <Link to= "/photo-gallery" 
            style={{textDecoration : "none",backgroundColor : "#1e1925",color : "white",padding: "15px 20px"}}
            >View Gallery</Link>

            

        </div>
    )
}

export default GallerySection;