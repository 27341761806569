
import canoe from '../assets/canoe.jpeg'
import gopal from '../assets/gudie2.jpeg'

export default function Guide({t}){


    return(
        <div className = 'guide' >
            <h2>{t('home.guide.title')}</h2>
            <div style={{'margin':'auto','width':'6rem'}} className='horizontal-line' ></div>
            <div style={{padding:'40px'}} >
                <div className='guide_desc1' >
                    <div className='sombote' >
                      <div>
                      <img src={canoe} alt = 'guide image 1'/>
                     </div>
                    
                   <p style={{marginTop:"10px",fontWeight :"bold" ,fontSize : "20px"}} >Som Bote</p>
                   <p style={{color: "grey"}}>Nature Guide</p>

                    </div>
                    <p>{t('home.guide.desc1')}</p>
                    </div>
                    <div style={{'margin':'auto','width':'6rem'}} className='horizontal-line' ></div>
                    <div className='guide_desc1' >
                   <p><span style={{fontWeight : "bolder", fontSize : "20px"}} >{t('home.guide.topic')}</span> <br/> <br/>{t('home.guide.desc2')}</p>
                    <div className='sombote' >
                      <div>
                      <img src={gopal} alt = 'guide image 1'/>
                     </div>
                    
                   <p style={{marginTop:"10px",fontWeight :"bold" ,fontSize : "20px"}} >Gopal Timalsina</p>
                   <p style={{color: "grey"}}>Senior Nature Guide</p>

                    </div>
                    </div>

            </div>
             {/* <div className="guide_div" >
                <div className="guide_card" >
                   <p>Namaste! I am Som Bote, the Nature Guide at Travellers Jungle Camp. I have been guiding tourists since 1994. I was born in 1978 in Padampur, an area previously located inside Chitwan National Park. It was relocated to the western part of the Chitwan district by the then king due to the risk of wild animals and for the conservation of the national park. Currently, I live in a small village near Sauraha known as Bachhauli. I love nature and believe in the sustainable use and preservation of it. After completing my Junior Guide Training in 1994, I started to work as a nature guide. I have been working at Travellers Jungle Camp since its establishment in 1986. I have about 30 years of experience with the various flora and fauna found in Chitwan National Park. I’ll do my best to answer all your questions, share fascinating stories, and make your time in Chitwan National Park unforgettable. Join me on a journey through the heart of the jungle. I look forward to guiding you and showing you the beauty and wonder of this incredible place.</p>
                   <div>
                    <img src={canoe} alt = 'guide image 1'/>
                   </div>
                   <p>Sombote</p>
                   <p>Nature Guide</p>
                </div>
                <div className="guide_card" >
                <p>Some descriptions</p>
                </div>
             </div> */}
        </div>
    )
}