import {useState,useContext,createContext} from 'react';


const PackageContext = createContext();


export function usePackage(){
    return useContext(PackageContext);
}


const PackageProvider = ({children}) =>{
    const defaultDate = new Date();
    let [open,setOpen] = useState(false);
    let [packageName,setPackageName] = useState('1 nights 2 day');
    let [adults,setAdults] = useState(1);
    let [childrens,setChildrens] = useState(0);
    let [country,setCountry] = useState('Nepal')
    let [phone,setPhone] = useState(0)
    let [rooms,setRoom] = useState('Standard Tripple Room');
    let [checkinDate,setCheckinDate] = useState(defaultDate.toISOString().slice(0, 10));
    let [checkoutDate,setCheckoutDate] = useState(defaultDate.toISOString().slice(0, 10))
    console.log(open)
    
    function changeCountry(a){setCountry(a)};
    function changeAdults(a){setAdults(a)}
    function changeChildrens(a){setChildrens(a)}
    function changeRoom(a){setRoom(a)}
    function changePhone(a){setPhone(a)}

    function changePackage(name){
        setPackageName(name);
    }

    console.log(checkinDate)
    function changeCheckin(date){
        setCheckinDate(date);
    }
    function changeCheckout(date){
        setCheckoutDate(date);
    }

    function openBook(){
        setOpen(true)
    }
    function closeBook(){
        console.log('close book')
        setOpen(false)
    }
    return(
        <PackageContext.Provider value = {{packageName,changePackage,adults,childrens,rooms,changeAdults,changeChildrens,changeRoom,changeCheckin,changeCheckout,open,closeBook,openBook,checkinDate,checkoutDate,phone,changePhone,country,changeCountry}}>
            {children}
        </PackageContext.Provider>
    )
}

export default PackageProvider;