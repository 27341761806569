import './css/App.css';
import Home from './pages/home';
import { Route,Routes,BrowserRouter } from 'react-router-dom';
import Navbar, { StickyBar } from './components/navbar';
import Gallery from './pages/gallery';
import Contact from './pages/contact';
import PageNotFound from './pages/pageNotFound';
import Package1 from './pages/packages/package1';
import PackageProvider from './context/packageProvider';
import Package2 from './pages/packages/package2';
import Package3 from './pages/packages/package3';
import Rooms from './pages/rooms/rooms';
import DeluxeRoom from './pages/rooms/deluxe-room';
import CottageRoom from './pages/rooms/cottage-room';
import StandardDoubleRoom from './pages/rooms/standard-double-room';
import ScrollToTop from './components/scrollTop';

function App() {
  return (
    <div className="App">
      <PackageProvider>
      <BrowserRouter >
      <Navbar/>
      <StickyBar/>
      <ScrollToTop/>
      <Routes>
      <Route index element = {<Home/>}/>
      <Route path = '/facilities' element = {<Home/>}/>
      <Route path = '/about-us' element = {<Home/>}/>
      <Route path = '/room/standard-tripple-room' element = {<Rooms/>}/>
      <Route path = '/room/deluxe-room' element = {<DeluxeRoom/>}/>
      <Route path = '/room/cottage-room' element = {<CottageRoom/>}/>
      <Route path = '/room/standard-double-room' element = {<StandardDoubleRoom/>}/>
      <Route path = '/contact-us' element= {<Contact/>} />
      <Route path = '/accomodation' element = {<Home/>}/>
      <Route path = '/tariff' element = {<Home/>}/>
      <Route path = '/photo-gallery' element = {<Gallery/>}/>
      <Route path = '/faqs' element = {<Home/>}/>
      <Route path = '/reservation' element = {<Home/>}/>
      <Route path = '/1night-2days' element = {<Package1/>}/>
      <Route path = '/2night-3days' element = {<Package2/>}/>
      <Route path = '/3night-4days' element = {<Package3/>}/>
      <Route path = "*" element = {<PageNotFound/>}/>
  
      </Routes>
      
      </BrowserRouter>
      </PackageProvider>

    </div>
  );
}

export default App;
