
import { useNavigate } from "react-router"
import manSelfieWithJeep from '../assets/manSelfieWithJeep.jpeg'
import groupPhoto from '../assets/groupPhoto.jpeg'
import package3 from '../assets/package3.jpg'
import { useTranslation } from "react-i18next"

export default function Package(){
   let navigate  = useNavigate();
   let {t} = useTranslation();
   function handleFirstPackage(){
    const newTab = window.open('', '_blank');
    newTab.location.href = '/1night-2days';
   }

   function handleSecondPackage(){
    const newTab = window.open('', '_blank');
    newTab.location.href = '/2night-3days';

   }
   function handleThirdPackage(){
    const newTab = window.open('', '_blank');
    newTab.location.href = '/3night-4days';


   }

    return(
        <div id = 'package' className="package_container" >
           <h2>{t('packages.title')}</h2>
           <div style={{'margin':'auto','width':'5rem'}} className='horizontal-line' ></div>
           <div className="package_details" >
            <div className="package_img">
            <img src = {manSelfieWithJeep} alt = "image" loading="lazy"/>

            </div>
           <div className="package_info">
            <h3>{t('packages.p1.title')}</h3>
            <br/>
            <p>{t('packages.p1.desc')}
                     <span onClick={handleFirstPackage} className="learn_more">Learn more</span>

            </p>

           </div>
           </div>

           <div className="package_details middle" style={{"padding" : "0px"}} >
           <div className="package_info">
            <h3>{t('packages.p2.title')}</h3>
            <br/>
            <p>{t('packages.p2.desc')}
            <span onClick={handleSecondPackage} className="learn_more">Learn more</span>

            </p>

           </div>
            <div className="package_img">
            <img src = {groupPhoto} alt = "image" loading="lazy"/>

            </div>
           
           </div>

           <div className="package_details" >
            <div className="package_img">
            <img style={{widht: "10%"}} src = {package3} alt = "image" loading="lazy"/>

            </div>
           <div className="package_info">
            <h3>{t('packages.p3.title')}</h3>
            <br/>
            <p>{t('packages.p3.desc')}
            <span onClick={handleThirdPackage} className="learn_more">Learn more</span>


            </p>

           </div>
           </div>
         
           
        </div>
    )
}