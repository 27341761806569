import '../css/activities.css'
import standarddeluxe from '../assets/standard-deluxe.jpeg'
import canoe from '../assets/canoe.jpeg'
import jeepsafari from '../assets/jeepsafari.jpeg'
import junglewalk from '../assets/junglewalk.jpeg'
import washelephant from '../assets/elephant.jpeg'
import tharu from '../assets/tharu.jpeg'
import tharuvillage from '../assets/tharuvillage.jpeg'
export default function Activities({t}) {
    return (
        <div className="activitiesContainer">
             <h2>{t('home.activities.title')}</h2>
             <div style={{'margin':'auto','width':'6rem'}} className='horizontal-line' ></div>
             <div className='activities_cards'>
                        <div className='activity_card'  >
                            <div className='actvity_card_image'>
                                <img src = {junglewalk} loading = 'lazy' alt = 'room image'/>
                            </div>
                           
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >{t('home.activities.act1.title')}</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }} >{t('home.activities.act1.desc')}</p>

                            <button className='read_more_btn' >{t('home.activities.readmore')}</button>

                            </div>
                        </div>
                        <div className='activity_card'  >
                            <div className='actvity_card_image'>
                                <img src = {jeepsafari} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >{t('home.activities.act2.title')}</p>
                            <p  style={{fontSize : "14px",color:"gray", marginBottom : "5px" }}>{t('home.activities.act2.desc')}</p>

                            <button className='read_more_btn' >{t('home.activities.readmore')}</button>
                            </div>
                        </div>
                        <div className='activity_card'  >
                            <div className='actvity_card_image' >
                                <img src = {canoe} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >{t('home.activities.act3.title')}</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }} >{t('home.activities.act3.desc')}</p>

                            <button className='read_more_btn' >{t('home.activities.readmore')}</button>

                            </div>

                        </div>

                        <div className='activity_card'  >
                            <div className='actvity_card_image' >
                                <img src = {washelephant} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >{t('home.activities.act4.title')}</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }} >{t('home.activities.act4.desc')}</p>
                            <button className='read_more_btn' >{t('home.activities.readmore')}</button>
                            </div>
                           
                        </div>

                        <div className='activity_card'  >
                            <div className='actvity_card_image' >
                                <img src = {tharu} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >{t('home.activities.act5.title')}</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }}>{t('home.activities.act5.desc')}</p>
                            <button className='read_more_btn' >{t('home.activities.readmore')}</button>
                            </div>
                           
                        </div>
                        <div className='activity_card'  >
                            <div className='actvity_card_image' >
                                <img src = {tharuvillage} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'10px'}} >
                            <p className='activity_title' >{t('home.activities.act6.title')}</p>
                            <p style={{fontSize : "14px",color:"gray", marginBottom : "5px" }}>{t('home.activities.act6.desc')}</p>

                            <button className='read_more_btn' >{t('home.activities.readmore')}</button>
                            </div>
                           
                        </div>


                    </div>
        </div>
        
        
    )
}