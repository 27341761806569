

export default function GoogleMap({t}){
 


    return(
        <div className="google_map">
     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.474239102442!2d84.49181477561467!3d27.578823131148102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3994ee40673e5009%3A0x8b4ee085418fdfeb!2sTravellers%20Jungle%20Camp!5e0!3m2!1sen!2snp!4v1682649668384!5m2!1sen!2snp" 
     height="450px" 
     style={{"border":"0",
     "flex" : '1'
     }}
     allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
     <div style = {{
        "flex":'1',
        'letterSpacing':'1px'
     }} >
        <h3>{t('home.map.title')}</h3>
        <br/>
        <p>{t('home.map.p1')}</p>
<p className="second_p">
    <br/>
    {t('home.map.p2')}
</p>
     </div>
        </div>
    )
}
