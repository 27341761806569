
import { useTranslation } from 'react-i18next';
import rhino from '../assets/rhino.mp4'

const OverView = ()=>{

    const {t} = useTranslation();

    return(
        <div className = "overview_div">
            <h3 className="overview_header" >{t('overview.title')}</h3>
            <div style={{'margin':'auto','width':'6rem'}} className='horizontal-line' ></div>
            <div className='overview_body' >
            <div className="overview_p" >
                <p>{t('overview.phase1')}

<br/>
{t('overview.phase2')}
<br/>

{t('overview.phase3')}
<br/>
{/* <br/>

{t('overview.phase4')} */}
</p>
            </div>
            <div>
                <br/>
                <br/>
                <p style = {{textAlign:"center"}} ><b> * Get a front row seat to a wildest surprise! *</b></p>
            < video style={{padding:"10px 0px"}}  width="600" height="360" controls autoPlay loop muted   playsInline>
  <source src= {rhino} type="video/mp4"/>

</video>
<p style = {{textAlign:"center"}}><b>Will you be lucky enough to spot a rhino from the comfort of your room?</b></p>
</div>
</div>
                
          
        </div>
    )
}

export default OverView