import '../css/contact.css';
import emailjs from 'emailjs-com';
import { useState } from 'react';
import handmadeimg from '../assets/handmade.jpeg'

export default function Contact(){
    const [to, setTo] = useState('');
    const [email,setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    let [name,setName] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      let body = {
        name: name,
        from_mail: email,
        subject: subject,
        message: message
      }
      emailjs.send('service_rg32drb', 'template_8bpks6e',body, 'x8MI_PJTuhBCeJTTm')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Email sent successfully!');
        }, (error) => {
          console.log('FAILED...', error);
          alert('Email failed to send.');
        });
    }
    return(
      <div style={{display:'flex', justifyContent:"center",marginTop:"40px"}} >
<div class="form-container">
  <div class="picture-container">
  <div class="picture">
 <img src = {handmadeimg}/>
  </div>
  </div>
  <div class="contact-form-container">
  <form class="contact-form" onSubmit={handleSubmit}>
    <span class="form-header">Contact us</span><br/>
    <input type="text" class="email-input" placeholder="Name" onChange={(e)=>setName(e.target.value)}  required/>
    <input type="email" class="email-input" placeholder="Email" onChange={(e)=>setEmail(e.target.value)} required />
    <input type="text" class="email-input" placeholder="Subject" required onChange={(e)=>setSubject(e.target.value)} />
    <textarea name="feedback" cols="30" rows="5" class="message" placeholder="Message..." onChange={(e)=>setMessage(e.target.value)} ></textarea>
    <button class="submit" type="submit">Submit</button>
  </form>
    </div>
</div>
        
</div>
    )
}