import  ReactDOM  from "react-dom"
import '../css/contact.css';
import emailjs from 'emailjs-com';
import { useState } from 'react';

export default function ContactModal({open,setOpen}){
    const [to, setTo] = useState('');

    const [email,setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const[firstname,setFirstname] = useState('');
    const [lastname,setLastname] = useState('');
   

    if(!open) return null;
    

  
    const handleSubmit = (e) => {
      e.preventDefault();
      emailjs.send('service_izqycl2', 'template_thwwa7t', {
        to:  'travellersjunglecamp@gmail.com',
        name : firstname+" "+ lastname,
        from: email ,
        subject,
        message
      }, 'ijobr_0mzRIjyU5bj')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Email sent successfully!');
        }, (error) => {
          console.log('FAILED...', error);
          alert('Email failed to send.');
        });
    }
    const defaultDate = new Date();
    function handleClick(){
        setOpen();
    }

    return ReactDOM.createPortal(
        <div className='modal'>
            <div className='booking_form' >
                <div className='close' onClick={handleClick} >X</div>
               <input className='form name_input'   placeholder='firstname' onChange={(e)=>setFirstname(e.target.value)} required />
               <input className='form lastname_input'  placeholder='lastname' onChange={(e)=>setLastname(e.target.value)} required/>
               <input className='form phone_input' type= "email" placeholder = "email"  onChange={(e)=>setEmail(e.target.value)} required />
               <input className= 'form subject_inut ' placeholder="subject" onChange={(e)=>setSubject(e.target.value)}/> 
               <textarea className= 'form message_input' placeholder = 'message' onChange={(e)=>setMessage(e.target.value)}/>
               
               <button className = ' form send_btn' onClick={handleSubmit} >Send</button>
               
               
            </div>

        </div>,document.getElementById('contact')
    )
}