import React from
 
'react';
import ReactDOM from
 
'react-dom/client';
import App from
 
'./App';

import i18n from
 
'i18next';
import { initReactI18next } from
 
'react-i18next';
import Backend from
 
'i18next-http-backend';
import LanguageDetector from
 
'i18next-browser-languagedetector';

// Import translation resources
import global_en from './translation/en/global.json';
import global_es from './translation/es/global.json';
import global_chi from './translation/chinese/global.json';

// Define language resources
const resources = {
  en: {
    translation: global_en,
  },
  es: {
    translation: global_es,
  },
  chi: {
    translation: global_chi,
  },
};

// Set default language based on localStorage or default to 'en'
let language = localStorage.getItem('language') || 'en';

// Initialize i18next
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: language, // Set initial language
    fallbackLng: 'en',
    debug: true, // Enable debugging mode
    resources,
  });

// Render the React application

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>

    
<App />

  
</React.StrictMode>
);