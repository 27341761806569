import '../../css/rooms.css';
import str2 from '../../assets/str2.jpeg';
import str1 from '../../assets/str1.jpeg';
import bed from '../../assets/bed.png';
import car from '../../assets/car.png';
import otherAmenities from '../../assets/other-amenities.png';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Modal from '../../components/modal';
import { usePackage } from '../../context/packageProvider';
import deluxecottage from '../../assets/deluxe-cottage.jpeg'
import washroom3 from '../../assets/washroom3.jpeg'
import washroom4 from '../../assets/washroom4.jpeg'
import garden from '../../assets/garden.jpeg'
import garden2 from '../../assets/garden2.jpeg'


export default function CottageRoom(){

    const {t} = useTranslation();
    let [open,setOpen] = useState(false);
    let {changeRoom} = usePackage(); 
    function handleClick(){
        changeRoom('Cottage Room')
        setOpen(true);
    }

    
    return(
        <div className='rooms'>
            <div className='rooms_image_container' >
                 <img loading='lazy' className='r1' src = {deluxecottage} alt = "room1"/>
                 <img loading='lazy' className='r2' src = {garden} alt = "room1"/>
                 <img loading='lazy' className='r3' src = {garden2} alt = "room1"/>
                 <img loading='lazy' className='r4' src = {washroom3} alt = "room1"/>
                 <img loading='lazy' className='r5' src = {washroom4} alt = "room1"/>
            </div>
            <div className='room_description_container'>
             <div>
                <h2>{t('room.room3.title')}</h2>
                <div className='basic_facillities'>
                    <div>
                        <img style={{'width' : '40px'}} src = {bed} alt = "bed"/>
                        <p>1 single bed & <br/>
                       1 double beds  </p>
                    </div>
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                    </svg>
                        <p>Maximum 3 <br/>people</p>
                    </div>

                    <div>
                    <img style={{'width' : '40px'}} src = {car} alt = "bed"/>
                        <p>Free pickup<br/> from bus stop</p>
                    </div>
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-wifi" viewBox="0 0 16 16">
                        <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"/>
                        <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"/>
                    </svg>
                        <p>Free <br/>wifi</p>
                    </div>
                </div>
                <h2>{t('room.title2')}</h2>
                <br/>
                <span style = {{'color' : 'gray','letterSpacing' :'1px'}} >
                 {t('room.room2.desc')}
                  </span>
                <br/>
                <br/>
                <h2>{t('room.offer')}</h2>
                <br/>
                <div className='amenities_container'>
                    <div className='amenities'>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVR4nO2YXYhNURTHf8ZI03jh5YZ8JHkgD3hR5GskTygUjVDXm9QkZWZSThJSPlKDeJCvB5QoD2qGqxQPlAeldJEieZAhZTDG0a7/rdXpuObcc/a5N/nX6uzW2fvs/zl77f9e60B6nAeKNDBKQBDx7QTaaGCCpRhf3VD6T/Bf/oKzgbfAS6DX2EfgHjC23gSfA3eAQzFWBk7Xm2AI7AKWx9gFLXXuaAU6gAciWM2+AzeBdUBTHuQ2Au8VdweABcB4YGSk32hgErAKOAt8BZ4Ac3wRGwGcAH4AnUBLwvETgMvAgF4ycxwBPgELUz6nAxgE1pAhViuWlmb0vL1Av0IgNUYBr4GDZIcm4CFwMYuHbdLbHgX2yVdUiuWwwkjJTGkfEug3RqjLuo/679dST05L8DpwBjgnqyzRXbW3AK/UXgz8UnuKZMZdkd/dR/23Ai+AHWkJfgA2APNkDtOBZWoXFKMVfWw3y1g02teu+6h/QS9+NQ25MfoK2/9wUqQ1R/BRreTGKVZCzzYEHJfOJsIl4KkJbB9o1ib7AmxLOtiJ8nrywUngRtJBz5TX7fZsXZKgnqQEZwFXTAI6oKXoiySmvQntvuLunfH1ZJHYFhXU7lS5pkzG+Zy2zQAmapJmXacqfldKBY4BtyVb/UayMkWojOaUsmh3Uvxtlw6qHAi18W75TGLdJEtUkLvC3GFIQu6W6TEwX/0266v1RcYGeRC0k4RVfPbEyZWg/YJhFYKtyrZzJxjnC2IIdmpjWJ/NhLwRtJN061gc7rJ7RdzELboOl6ANj1wIfgbmJiDoNQZ/qmiqhUwo3x4jPZljkWqUWgiWdbIU9D/HK+zE3TGb5JsE2+tyVsNa4HDEFxgy05SA2tql7ggMwUr9YU+SuqPNyIet4BoStgZOjd/RlhiKoFCAdgAAAABJRU5ErkJggg=="/>
                    <span>Hot/Cold Shower</span>
                    </div>
                    <div className='amenities'>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxklEQVR4nO2Yy0oDMRSGP/sIdaPdq4gLF76CoOMI6rtYuxdfwL0g6E7ciXZRL+9i3XkBLxuLNhI5xTB07FxrEueDAzPJycz/k+QME/hhHbgC3gBlebyK1pAIexaIyxq75kzohndgB2hgPw2gJZq19jXdeC03usM1WqL9Ellv+mYa95gS7S8Ya81V1EC/rUZUQl1WGpk3rqO6zD6rjWwBfeDIWPs6JoF94APYdMHINtATLc+GrsF1T3KsN6KZAU6GfPQ6wELMGGuNnLluxJulpTfyqM2+4YIRjRflN4rTH8QsVEZsQ3m7tJTjwYMFIvLGvTbSlpsm7tEU7W3z8OERqOMOddGsxMM3HQuWR9a4Md0tAp8WiFIpQ2teik7VoXSeAxPYSw24EK1a89BDrydJOAZmLTNUk3+SU2NPxx4mrhjnXHExQJ+73gFdIIh5Xp4c9Uvoc6zlUc7nZMq68n8QZ6RrtN3GPCtPjopEX/oP5E+yMMo2MjYCEaFfvlpyTsW/IUxQjcrIK5ykG7TovMLxxkiQsNIUnVfhLWHK6lJ2fmbSbsqy8zPjjZEgZXUpO7/CWcKcVeSvxxe2+f56vH9GgpxVZGzjvwCWLFp+i+9uGwAAAABJRU5ErkJggg=="/>
                    <span>Air Conditioning</span>
                    </div>

                    <div className='amenities'>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE4ElEQVR4nM2YfWjVVRjHv6ZFJGyVUf0RrFH7owmxMjKTWsoiG1SYf1QqLiuI+rOXP+yNNaIXFCF7hWBufwiKmBD+YcTs1aVpNN/IBWVbFGwQ2C2tO2/3xhOfA19/3dV2d+/sgQO/87yf8zznOc/5SZODayWNSlqqycNSZENHzeBSST9JOilp/iTkbkAmZC9RjeE6jB2TNHsC/LPhPSFpXrV36y1JzWVoj0sqSVo3AT3r4H2sDK0ZG2Fr0tAiaUzSb5I6MrRzoe0yXIRuK8PD+CG8IePQge4xbFUEN0oaZgc2SjoP/M3g1hjvu5KKjG2GXwNvyAgdG8ENY2NKMEfSDhRGOAKeYt7K/HxJpyS9z4jvemitmcW8zXwHuqsCMygRfczfwMgVzBdbnqX8XATtSuYhI9JiFJ1Vhf2SjvL9GkYPSbpJ0kPMl0i6ne8HoR1iHjIB36Cr6vAm+dVAwj9H6SiYE/MZ8X0Q2gl4Q+ZyaKGrahBhvEPSqyjvlHQWtCYcKTGuZqT5QXiUyd0XJF1veVoRXCbpYzPm4wdJq8mjOkkHwC/gVJbA1cHzgKRvy+j5Q9LmSg/L1yjZxSGIFd/J6keh9Uo6R9Jy5kG/i+/l3CJbmY9IeknSMkltku7l0AVtbyUOviPpTxT0oTTBBVZ6PrBdixA+zfetkr7iezO7meAaST3sYAm+ik71VSgaQ9EXdCWRgzOpi4F/T1JO0j5GLOwwtGcwPpPd87T5SNLd0KYEDZSJkyg+ImkV4d0G7lSZHHuZIv6EpO8t77qncr39G1ws6UVJxzF2TNJ6c2g3I803cd+WaLWeRUfNoZ6ra8ScSTeFqHMlS4sV7Pa0wwJul3DkfsOvBvcJzeoZceyzTL71UztjfJ6h7UZmWmAZV16eEx7lZG2ZA7IWWg+8RU5szWERDoThBEvKOHib0XsyHU7N4VN2pcG646yDUYYETx6ZaYO2TAM7g+twmLHYboZUzP0mqimE4UfJqZ0Z2mGGw054H5kO5+YRqtiRQUmNE3CwkSY1lZ2qPj0dLuSaiivt9XF6uQOSBsrg6ynkIfs7zUZNQtsxzjs5Qdt/5FozOqr+HjmjUEdu7P+fjb6URnOtduUyTKkPHDHcgPEPGX7Q8IOGHzL8gOFTozGWsZkz/rlZBwtWYJ83fLRMt9BYbjL8MI+qWOkew3/JoYoi/Z3ho/0/mw7cHXkFm/dk+srTHOxlteHkdnBhtB1lOf4aBH4LyV5AZh/4LkYJXNLXgUwJHUlfuy1su+nrLedgJzUrNaNHM78vimZgFviHbbUbLK83GD54hExaYNF+m9Rby3YcHzrHc7DLFBRoNGfZ6ousfGEmrEVW3sgYsgXtgXchsgm/Bd0rsJXwXeM5OGRPwBYLT78pbLXwpMMSCldaeJLcSlvwgMm12oL7Ta4F2+7LPw7JjxbWJrqQ1HCmsLYbf7eF9UnDx3eCbsO3W7jT2yVvfx/q8aHsISnYjnhY8xZuD2vgc5zIFNaC7UijndZ8JtwprPlMuLsyvpzm4HozfsQEm3gyFiyZu8x4jnKTFpGMBy7RgzcZDx1BD52hO21Eshk+pJfi3w5eRNG8j9XFw/pXjnoKa+xG/Ar5hT9VCcJwPCd/5ldHgvgOXITL/6CGbOgIXakTChthK2zGQz58CF/Cpzl/AbRiMknM7LywAAAAAElFTkSuQmCC"/>
                    <span>Mosquito Net</span>
                    </div>

                    <div className='amenities'>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAADQ0lEQVR4nO1YTUiUURQ9/UlIv9CihQYV9GdE0NIKDFdlIURRYYuiTEQXgmCQCUFLEdtYg6sEKYOBFFq4sYWBROO06W+hRFktHEK0JLJs4sa5w+31fW8m0GaC78CFzuPc+8687737ngG5oRZAeoGjNse5/8pgHEDMEymGTxNfTIP7sugSDB+kRmQwDIloBUMQ7cGFWsEansKweMHwaWoW8xQXfKO+zn+HxRuGTyM1oj4YhkTUB0MQ9cH/fgV3AzgFoLQADJYCOE1PmcnnmfQZQHkeDe6nhzQ9XZDB9wCeATgG4BOA/oDEDSxcnGXynQwfillLaroYADAD4CiA5wDeyeAHGizPcof+yyi3Bi+aT1xIMa+fGNyQsnemzZ05SGEzeQd5N3kd+bDnLh6mpo68m7yDvJl80ORM00uZ+/2fOhu8D8AcgGXkZ1isgnw1eafnkNygZhX5IXI5pWDtbwDumpxRAEnXXBGAWQB3zJiIxg3XX7uDfKN54YQZ1JeLaMFc/SqK146hPnoRTxkcYGID+RKe6PtG00mNrJxgK3mLx2ALNaINW/V+ziVzChqpkZaTwTUO7iLfQi7jintsAYq91NR7DNZTI1rFDGu5c28mLwuYG48BTBh+giLpjYpHAF4GrHqNx+DZgNV4xcOjqKbmuBmTgzYCs1HlMNw2gqvOrxKMARgy/DA11R6DOrloFUOsBWerXDFjPQC+AlgqpISCNiO4yT60wozJFdRr+EnmVXoMVlIjWkUvaymKOFeXGWtjXon2QCFNRnCLx3+5GfvI//hRyOeXvCqPwaqArRJnLYUswnfOqWhi3q8Hw3oAP7hqissUbHP6pG0HB3PYg/onpmgVSdZSbA/oBl30tE4HRnjvaVOuYNI5kyQtZ8rwPU5rCjLYQI1oFVNO+zrvXACy794CeGI0mWtLXhGClQC+cLMq9KrTV8gm8laPwVZqRAvm6lWn6OFcMqfgSMAPx1p2b3nuKB46recSE/W9uIa83WOwnRrRgrlp1lJMcC7FAL3I1vsND9hudJIUiyXJx8jHyeXOFD5pcmYZyiepGSUfJx8jT5KnTM4cvfyBGMWJPEeaXkIN5hvpbAZjeY50NoOFEBmDPwGcog5/cAqIFwAAAABJRU5ErkJggg=="/>
                    <span>Garden Facing Balcony</span>
                    </div>

                    <div className='amenities' >
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAACv0lEQVR4nM2X34tNURTHP0YYMjfSJZQoTcKDug8jD0YpvxVe5McYcbt5EIXSeFQ8aSKDeJj5C3gxihc8yItRCvkxJUpKZvLjZXhxtep76rQ795yzzbl75lv74d6z9tqfs/bZa60NYbQB+AIsYJKqBtSBCpNUtYkEnAa0FwA4BVhJE3QY+AucGAdgC9Avm9VFA7YBT1MgVwB9WtyeL06B61ckC1fJgbRFDgKvtXB8mM1joDMBzn43TaUY5JAW/QScB7YBa4HdwFXgp+yeh4KLNEeL26KXgFaSVQYGZTcUCs50Sota1LI0FbinSK4ngFqBb8ALj4iUFfFHBNBORa/bc16forioSJh25bRKbNwQ4BJPX3tiKcj16aakXFqmN3bTRzSme/pbl+Jr5H8ALcftVXWoxUZ0Kn07lu2ady3B50YK1H4ttMtz3gXNy6rn49ZcYAy47zFnJvAZeEUg9SoaVuby6LLsLfpBVFJe+w0cyEjSFwX3LBRcS6zwR+XugdLIQvWNS4Eq8DJml9WqFQY3ECv8JW33WIP0YRXnpGp3WqtWONyAU+YMYB9wV8+vK63MSGnVCld3AzifjjoOuapowDJwKKNByHMnsc78iL7VTG0G3gO/gB5/5ube6mYBP5RAH8rxOacE2UncKtvggGvk7Lg+5pGUYj6qW11QwIqcmVPT/IQ2qAM4qs8gTw94THb28t562yA6EWCaZgtyNGO7e3VC7UB56zRwMzZuO4CdqgSNVJX9lgbPl+uwPaEguVts1eBKin2H7G3LXdlB+gD8kV1TAOuKbKRbun+49h91hYzGV/3/3bEPArgjB+AgcBaYVyRcHkCfLS5EZzIOSRZgVTZWfZqiNxlpJg2wDRhWMs9bVZqyxXcSErVFbljPuwgot9TVM+6tXQRWUrPQk9AsWGIOtq2uNgHvdGewVDFh+gcy3gQIEMANWAAAAABJRU5ErkJggg=="/>

                        <span>Solar Backup</span>
                    </div>

                    <div className='amenities'>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgElEQVR4nO3XsQmFMBSF4VO4hW7hCIKjZKcskSGcx9JesQs2KU4CF/w/OLbv8ovFkwA0peDTHXx6H0XSGmylPjArnsyBJgq6KOiioIuCLgq6KOiioIuCLgp2LXgE+P+bPjvqAyNPa/A1LQN/fJZpknQNfH2nOtgHfghbjwOBX3sAgf7snSxndMIAAAAASUVORK5CYII="/>
                    <span>Television</span>
                    </div>
                </div>

             </div>
             <div className='price_container'>
                  <h4><s>30$ </s> 24$</h4>
                  <div style={{'borderTop':'1px solid black'}} ></div>
                  <br/>
                  <p style={{'letterSpacing':'1px','color':'gray'}} >- Air conditioning <br/>- Garden view</p>
                  <button onClick={handleClick}  className='book_button' >Book now</button>
                  <div style={{'display':'flex','justifyContent':'center'}}>
      

                  <div className="horizontal-line"></div>
               <span>OR</span> <div className="horizontal-line"></div>
   

               </div>
               <p style={{'textAlign':'center'}} >WhatsApp : <br/> +977 9845025845</p>
             </div>
            </div>
            <Modal open={open} close = {()=>setOpen(false)} />
        </div>
    )
}