
import '../../css/package.css';
import package3 from '../../assets/package3.jpg'
import { useState } from 'react';
import Modal from '../../components/modal';
import { usePackage } from '../../context/packageProvider';

export default function Package1(){
let [open,setOpen] = useState(false);
let {changePackage} = usePackage(); 

function handleClick(){
    changePackage('3 night 4 days')
    setOpen(true);
}


    return(
        <div className="package1" >
           <div className='day1'>
             <div className='day1_img'>
             <img src = {package3} alt = "sample" loading='lazy'/>
             <div>
                <button className='package_book_btn' onClick={handleClick} >Book now</button>
             </div>
             </div>
             <div className='day1_desc' > 
               <h3>3 night 4 days package</h3>
               <br/>
               <p>DAY 1</p>
               <br/>
               <ul>
                <li>[13:00] - Travelling day to chitwan. Free pickup from bus stop and paid pickup from the airport.</li>
                <li>[14:00] - Lunch at the hotel resturant</li>
                <li>[14:30] - Trip discussion at T.J.C(Complementary service)</li>
                <li>[16:00] - Short sight-seeing around the park area and village tour to nearby ethnic Tharu village where you will have chance to learn about tharu culture and lifestyle.</li>
                <li>[21:00] - Return to the hotel for dinner</li>
               </ul>

               <br/>
               <p>DAY 2</p>
               <br/>
               <ul>
                <li>[06:00] - Wakeup call</li>
                <li>[06:30] - Breakfast</li>
                <li>[07:00 - 11:00] - Half Day Jungle Walk (An excellent opportunity for bird watching and for seeing 2 rare species of crocodile and explore the flora and fauna found in the national park)</li>
                <li>[11:30] - Lunch at the hotel</li>
                <li>[13:00 - 17:00] - Half Day Jeep safari inside the national park. (Combined) Note --(Private safari can be arranged) (An excellent opportunity to see four different kinds of deer, rhinoceros, wild boar, monkey, leopard, sloth bear and the Royal Bengal Tiger (If you are lucky). You will also encounter many other smaller mammals that have made Chitwan their home.)</li>
                <li>[20:00] - Dinner</li>
               </ul>

               <br/>
               <p>DAY 3</p>
               <br/>
               <ul>
                <li>[06:00] - Wakeup call</li>
                <li>[06:30] - Breakfast</li>
                <li>[07:00 - 11:00] - 45 minutes of canoe trip followed by the visit to elephant breeding center.</li>
                <li>[14:00] - 2 hours jeep safari in the buffer zone area</li>
                <li>[20:00] - Tharu Cultural Dance presentation where you can also participate together with the local villagers.</li>
                <li>[21:00] - Dinner</li>
               </ul>

               <br/>
               <p>DAY 4</p>
               <br/>
               <ul>
                <li>[6:00] - Wakeup call</li>
                <li>[6:30] - Breakfast</li>
                <li>[7:00] - Departure for onward journey</li>
                
               </ul>

             </div>

              
           </div>
           <Modal open={open} close = {()=>setOpen(false)} />
        </div>
    )
}